/**
*  UI-REFACTORED
*    FROM --> ./legacy-web/src/main/js/js/aa/contactAA/services/formInitializeService.js
*    TO   --> ./legacy-web/src/main/assets/js/contact-aa/services/form-initialize-service.js
**/

/* global contactModule:true */

angular.module('com.aa.contact').service('FormInitializeService', function ($rootScope, TopicChangeDialogService) {

    var subjectsContainer = '#subjectList';
    var reasonsContainer = '#reasonList';

    var initForm = {
        initContactForm: function (formModel) {
            resetDropdownValuesIfInvalid(formModel);
            initPhones(formModel);
            initFlightInfo(formModel);
            initEventsForGmtForm(formModel);
            showHideForm(formModel);
        },

        initTopicSelectionForm: function (formModel) {
            var topic = formModel.topicInfo.topic;
            var isValidTopic = contactModule.topics[topic] || false;
            if (isValidTopic) {
                formModel.topicInfo.subject = contactOptions.getDefaultSubjectByTopic(topic);
            } else {
                formModel.topicInfo.topic = '';
            }
        },

        handleTopicChange: function (topicInfo) {
            var isLandingPage = angular.element('#topicSelectionForm').length > 0;
            if (isLandingPage) {
                contactOptions.init(topicInfo.topic);
                $rootScope.$broadcast('topicChanged');
            } else {
                TopicChangeDialogService.showTopicChangeDialog(topicInfo);
            }
        },

        handleSubjectChange: function(topicInfo, subjectInfo) {
            var showNext = (angular.element("#" + topicInfo + "-" + subjectInfo).data("hidenext") !== true);
            contactOptions.displayOrHide('#formButtonRow', showNext);
            let showForm = (angular.element("#" + topicInfo + "-" + subjectInfo).data("hideform") !== true);
            contactOptions.displayOrHide('#formSection', showForm);
        }
    };

    var resetDropdownValuesIfInvalid = function (formModel) {
        var topic = formModel.topicInfo.topic || '';

        var subjects = contactModule.subjectsMap[topic] || [];
        formModel.subjects = subjects;
        if (!isValidDropdownValue(subjects, formModel.topicInfo.subject)) {
            formModel.topicInfo.subject = '';
        }

        var reasons = contactModule.reasonsMap[topic] || [];
        formModel.reasons = reasons;
        if (!isValidDropdownValue(reasons, formModel.topicInfo.reason)) {
            formModel.topicInfo.reason = '';
        }
    };

    var initPhones = function (formModel) {
        var hasContactInfo = formModel.contactInfo || false;
        if (hasContactInfo) {
            formModel.contactInfo.emailPhoneForm.phones.push({"countryCode": "", number: ""});
            formModel.contactInfo.emailPhoneForm.phones.push({"countryCode": "", number: ""});
        }
    };

    var initEventsForGmtForm = function (formModel) {
        var isGmtForm = formModel.groupMeetingTravelInfo || false;
        if (isGmtForm) {
            var firstEvent = formModel.groupMeetingTravelInfo.events[0];
            firstEvent.itinerary.departureDateFormat = contactModule.dateFormat;
            firstEvent.itinerary.returnDateFormat = contactModule.dateFormat;
        }
    };

    var initFlightInfo = function (formModel) {
        var hasFlightInfo = formModel.flightInfo || false;
        if (hasFlightInfo) {
            formModel.flightInfo.flightDateFormat = contactModule.dateFormat;
        }
    };

    var isValidDropdownValue = function (items, value) {
        var values = [];
        $j.each(items, function (index, item) {
            values.push(item.value);
        });
        return values.indexOf(value) >= 0;
    };


    var contactOptions = function () {
        var self = {};

        self.init = function (selectedTopic) {
            selectedTopic = selectedTopic || '';
            self.processSubject(selectedTopic);
            self.processReason(selectedTopic);
        };

        self.processSubject = function (selectedTopic) {
            var subjects = contactModule.subjectsMap[selectedTopic] || [];
            self.displayOrHide($j(subjectsContainer), subjects.length > 0);
            //self.populateDropdown(subjects, $j(contactModule.subjectSelector));
        };

        self.processReason = function (selectedTopic) {
            var reasons = contactModule.reasonsMap[selectedTopic] || [];
            self.displayOrHide($j(reasonsContainer), reasons.length > 0);
            //self.populateDropdown(reasons, $j(contactModule.reasonSelector))
        };

        self.displayOrHide = function (element, display) {
            if (element.length <= 0) {
                return;
            }

            if (display) {
                $j(element).show();
            } else {
                $j(element).hide();
            }
        };

        self.populateDropdown = function (items, selectBox) {
            selectBox.find('option:gt(0)').remove();
            $j.each(items, function (index, item) {
                selectBox.append('<option value="' + item.value + '">' + item.name + '</option>');
            });
            if (items.length == 1) {
                selectBox.find('option:gt(0)').attr('selected', 'true');
            }
        };

        self.getDefaultSubjectByTopic = function (topic) {
            topic = topic || '';
            var subjects = contactModule.subjectsMap[topic] || [];
            if(subjects.length === 1){
                initForm.handleSubjectChange(topic, subjects[0].value);
                return subjects[0].value;
            }else{
                return '';
            }
        };

        return self;
    }();

    let showHideForm = function (formModel) {
        let topicInfo = formModel.topicInfo.topic;
        let subjectInfo = formModel.topicInfo.subject;
        let showForm = (angular.element("#" + topicInfo + "-" + subjectInfo).data("hideform") !== true);
        contactOptions.displayOrHide('#formSection', showForm);
    };

    return initForm;
});
